import { setUTMString } from '@utils/setUTMString';
import { useEffect } from 'react';

const UtmHandler = () => {
    useEffect(() => {
        setUTMString();
    }, []);

    return null;
};

export default UtmHandler;
